<template>
  <b-sidebar
    id="view_meeting_sidebar"
    ref="view_meeting_sidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="getMeetingData(itemId)"
    @hidden="resetFormData()"
    width="60%"
  >
    <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="hide">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1 border-bottom"
      >
        <div class="d-flex justify-content-start align-items-center">
          <!-- avatar -->
          <b-avatar :src="meeting_data.user.avatar" size="50" class="mr-1" />
          <!--/ avatar -->
          <div class="profile-user-info">
            <h6 class="mb-0">
              {{ meeting_data.user.name }}
            </h6>
            <small class="text-muted">{{
              meeting_data.created_at | formatDateTime
            }}</small>
          </div>
        </div>

        <div>
          <!-- <b-link class="text-primary" @click="hide">
            <feather-icon icon="XIcon" size="22" class="mr-25" />
          </b-link> -->
        </div>
      </div>
      <b-card
        tag="article"
        class="mb-2"
        :id="`meeting-card-${meeting_data.hashid}`"
        :img-src="meeting_data.poster_img"
        img-height="300px"
      >
        <div class="d-flex mb-1 justify-content-between">
          <div class="pr-1 mr-1 border-right text-center">
            <p class="text-uppercase mb-0">
              {{ meeting_data.start_date_n_time | getMonth }}
            </p>
            <h2>
              {{ meeting_data.start_date_n_time | getDate }}
            </h2>
          </div>

          <div class="w-100">
            <b-card-title class="">{{ meeting_data.name }}</b-card-title>
            <b-card-sub-title>
              Organized by {{ meeting_data.user.name | str_limit(30) }}
            </b-card-sub-title>
          </div>
        </div>
        <div class="media1 mb-1 d-flex align-items-center">
          <div class="media-aside mr-1 align-self-start">
            <span
              class="b-avatar badge-light-primary rounded"
              style="width: 34px; height: 34px"
              ><span class="b-avatar-custom">
                <feather-icon size="18" icon="CalendarIcon" />
              </span>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mb-0">
              {{ meeting_data.start_date_n_time | formatDate }}
            </h6>
            <small
              >{{ meeting_data.start_date_n_time | formatTimeFormDate }} to
              {{ meeting_data.end_date_n_time | formatTimeFormDate }}</small
            >
          </div>
        </div>
        <div class="media mb-1" v-if="meeting_data.location">
          <div class="media-aside mr-1 align-self-start">
            <span
              class="b-avatar badge-light-primary rounded"
              style="width: 34px; height: 34px"
              ><span class="b-avatar-custom">
                <feather-icon size="18" icon="MapPinIcon" />
              </span>
            </span>
          </div>
          <div class="media-body">
            <h6 class="mb-0">
              {{ meeting_data.location }}
            </h6>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <b-avatar-group size="34" class="ml-1">
            <b-avatar
              v-for="(user, i) in meeting_data.accepted_attendees"
              v-if="i < 5"
              :key="i"
              v-b-tooltip.hover.bottom="user.name"
              class="pull-up m-0"
              :src="user.avatar"
            />
          </b-avatar-group>
          <b-link
            v-if="meeting_data.accepted_attendees.length > 0"
            class="text-primary text-nowrap ml-50"
            v-b-modal="'show-attendees-' + meeting_data.hashid"
          >
            {{ meeting_data.accepted_attendees.length }} member attending this
            meeting.
          </b-link>
          <b-modal
            :id="'show-attendees-' + meeting_data.hashid"
            hide-footer
            modal-class="modal-primary"
            scrollable
            title="Attendees"
            class="text-danger"
          >
            <b-list-group
              v-if="
                meeting_data.accepted_attendees &&
                meeting_data.accepted_attendees.length
              "
            >
              <b-list-group-item
                v-for="user in meeting_data.accepted_attendees"
                :key="user.hashid"
              >
                <b-avatar :src="user.avatar" size="24" class="mr-1" />
                {{ user.name }}
              </b-list-group-item>
            </b-list-group>
            <b-card-text
              v-else-if="
                meeting_data.accepted_attendees &&
                meeting_data.accepted_attendees.length == 0
              "
            >
            <div class="item error text-center my-2 empty-data">
              <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
              <h4> OH NO!</h4>
              <p class="d-block mb-1">No Records Found!</p>
            </div>
            </b-card-text>
            <b-card-text v-else-if="meeting_data.accepted_attendees == null">
              <div class="d-flex align-items-center">
                <strong>Loading...</strong>
                <b-spinner class="ml-auto" variant="primary" />
              </div>
            </b-card-text>
          </b-modal>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div>Going?</div>
          <div class="demo-inline-spacing">
            <b-form-group>
              <b-form-radio-group
                v-model="meeting_data.isGoing"
                @change="updateIsGoing"
                :options="optionsCheckbox"
                name="buttons-1"
                buttons
                button-variant="outline-primary"
              />
            </b-form-group>
          </div>
        </div>
        <b-card-text v-html="meeting_data.description"> </b-card-text>
        <b-img
          v-if="meeting_data.image"
          rounded
          class="mb-25"
          :src="meeting_data.image"
          style=""
        />
        <!--/ meeting img -->

        <div
          v-for="(banner, banner_index) in meeting_data.attachments"
          :key="'banner-' + banner_index"
        >
          <b-img
            v-if="banner.type == 'image/png' || banner.type == 'image/jpeg'"
            rounded
            class="mb-25"
            height="500px"
            :src="banner.link"
            style=""
          />
        </div>

        <div
          v-for="(video, video_index) in meeting_data.attachments"
          :key="'video' + video_index"
        >
          <b-embed
            v-if="video.type && video.type.startsWith('video/') && video.link"
            type="video"
            class="rounded mb-50"
            aspect="16by9"
            controls
            autoplay="false"
            autostart="false"
          >
            <source :src="video.link" type="video/webm" />
            <source :src="video.link" type="video/mp4" />
          </b-embed>

          <embed
            class="mt-1 rounded"
            v-else-if="video.type == 'application/pdf'"
            :src="video.link"
            frameBorder="0"
            scrolling="auto"
            width="100%"
            style="min-height: 50vh"
          />
        </div>

        <div class="d-flex align-items-center">
          <div v-if="meeting_data.attended_users.length > 0" class="d-black">
            Attended Users
          </div>
          <b-avatar-group size="34" class="ml-1">
            <b-avatar
              v-for="(user, i) in meeting_data.attended_users"
              v-if="i < 5"
              :key="'attended_users_' + i"
              v-b-tooltip.hover.bottom="user.name"
              class="pull-up m-0"
              :src="user.avatar"
            />
          </b-avatar-group>
          <b-link
            v-if="meeting_data.attended_users.length > 0"
            class="text-primary text-nowrap ml-50"
            v-b-tooltip.html.hover="
              'Attended by ' + getNames(meeting_data.attended_users)
            "
          >
            {{ meeting_data.attended_users.length }} member.
          </b-link>
        </div>

        <!--/ likes comments  share-->
        <b-overlay :show="isBusy" no-wrap> </b-overlay>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BImg,
  BEmbed,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import { kFormatter } from "@core/utils/filter";
// file-pond dependencies
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import QuillInput from "@/components/QuillInput.vue";
import $ from "jquery";

// Create FilePond component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageValidateSize,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

export default {
  components: {
    BSidebar,
    BImg,
    BEmbed,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
    FilePond,
    QuillInput,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    itemId: {
      type: String,
      required: false,
      default: "",
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      optionsCheckbox: [
        { text: "Yes", value: "accepted" },
        { text: "No", value: "declined" },
        { text: "Maybe", value: "tentative" },
      ],
      isBusy: true,
      data_local: {
        channel_type: "community",
      },
      customFields: [],
      support_categories: [],
      priority_option: [],
      status_option: [],
      myFiles: [],
      channel_types: [],
      channel_type_select: [],
      meeting_data: {
        user: {},
        attendees: [],
        accepted_attendees: [],
        attended_users: [],
      },
      comment_id: null,
      userData: this.$cookies.get("userData"),
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-tickets";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, ticketStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  computed: {},
  watch: {
    sidebarMeetingViewActive(val) {
      if (val) {
        this.getMeetingData(this.itemId);
      }
      this.data_local = {
        channel_type: "community",
      };
      this.myFiles = [];
      if (this.itemId) {
        this.data_local = this.itemData;
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    getNames(users) {
      let str = "";
      users.forEach((user) => {
        str += "<br />" + user.name;
      });
      return str;
    },
    updateIsGoing() {
      const self = this;
      if (!this.itemId) {
        console.log("no itemId");
        return true;
      }

      let data = {};
      data.url = "/meetings/" + this.itemId + "/update-status";
      // self.meeting_data._method = "PATCH";
      data.params = {
        isGoing: self.meeting_data.isGoing,
      };
      self.isBusy = true;
      self.$store
        .dispatch("app/update", data)
        .then((res) => {
          self.isBusy = false;
        })
        .catch((error) => {
          self.isBusy = false;
          console.log(error);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Something went wrong",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    kFormatter,
    addReply(comment, hashid) {
      const self = this;
      self.comment_id = comment.hashid;
      self.reply_to = comment.user.name;
      document.getElementById("comment-field-" + hashid).focus();
    },
    removeReply() {
      const self = this;
      self.comment_id = null;
      self.reply_to = data.null;
    },
    getMeetingData(itemId) {
      const self = this;
      if (!itemId) {
        console.log("no itemId");
        return true;
      }

      let data = {};
      data.url = "/meetings/" + itemId;

      self.isBusy = true;
      self.$store
        .dispatch("app/get", data)
        .then((res) => {
          self.meeting_data = res.data.data;
          self.isBusy = false;
        })
        .catch((error) => {
          self.isBusy = false;
          console.log(error);
        });
    },
    resetFormData() {
      console.log("reset");
      const self = this;
      self.meeting_data = {
        user: {},
        attendees: [],
        accepted_attendees: [],
        attended_users: [],
      };
    },
    openWindow(link) {
      window.open(link);
    },
    fetchItem(hashid) {
      this.$store
        .dispatch("app-tickets/fetchTicket", hashid)
        .then((res) => {
          this.data_local = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateMeetingArray(data, index) {
      self.meeting_data = data;
    },
  },
};
</script>

<style lang="scss">
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
