<template>
  <div class="w-100">
    <textarea :value="cleanContent" :name="name" :id="id" v-show="false">
    </textarea>
    <quill-editor
      v-model="content"
      :content="value"
      :class="className"
      name="body"
      :options="editorOption"
      ref="myQuillEditor"
      @input="(val) => $emit('update:value', val)"
    >
    </quill-editor>
    <div v-if="showEmojiPicker" class="emoji-picker-overlay">
      <emoji-picker @emoji-click="addEmoji"></emoji-picker>
    </div>
  </div>
</template>

<script>
import "quill-mention/dist/quill.mention.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "emoji-picker-element";
import "quill-emoji/dist/quill-emoji.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { quillEditor } from "vue-quill-editor";
import ImageCompress from "quill-image-compress";
import Quill from "quill";
window.Quill = Quill;
import Mention from "quill-mention";
Quill.register("modules/mention", Mention);
// Quill.register("modules/imageCompress", ImageCompress);
import ImageResize from "quill-image-resize";
Quill.register("modules/imageResize", ImageResize);
import * as Emoji from "quill-emoji";
Quill.register("modules/emoji", Emoji);

const Link = Quill.import("formats/link");

class linkType extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (value.startsWith("https://") || value.startsWith("http://")) {
      node.className = "link--external";
      node.setAttribute("target", "_blank");
    } else {
      node.setAttribute("target", "_blank");
    }
    return node;
  }
}
Quill.register(linkType);

//AutoDetect URLs
import QuillAutoDetectUrl, {
  QuillAutoDetectUrlOptions,
} from "quill-auto-detect-url";

Quill.register("modules/autoDetectUrl", QuillAutoDetectUrl);

import $ from "jquery";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    quillEditor,
    QuillAutoDetectUrlOptions,
  },
  name: "QuillInput",
  props: {
    value: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    toolbar: {
      required: true,
    },
    mentionList: {
      required: true,
    },
    name: { type: String, required: false, default: "" },
  },
  data() {
    return {
      body: this.value,
      showEmojiPicker: false,
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              ["link", "image", "video"],
              ["clean"],
              [{ 'emoji': 'emoji' }]
            ],
            handlers: {
              emoji: this.toggleEmojiPicker,
            },
          },
          imageResize: {
            modules: ["Resize"],
            // displaySize: true,
          },
          mention: {
            mentionListClass: "ql-mention-list custom-mention-list",
            allowedChars: /^[a-zA-Z0-9_]*$/,
            mentionDenotationChars: ["@"],
            dataAttributes: [
              "id",
              "value",
              "denotationChar",
              "link",
              "hover",
            ],
            offsetLeft: $("body").hasClass("rtl") ? -250 : 0,
            renderItem: (item) => {
              return item.display;
            },
            source: (searchTerm, renderList, mentionChar) => {
              let values = this.mentionList;

              if (searchTerm.length === 0) {
                renderList(values, searchTerm);
              } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                  if (
                    ~values[i].value
                      .toLowerCase()
                      .indexOf(searchTerm.toLowerCase())
                  )
                    matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            },
          },
          autoDetectUrl: true,
          // imageCompress: this.toolbar
          //   ? {
          //       quality: 0.8, // default
          //       maxWidth: 1000, // default
          //       maxHeight: 1000, // default
          //       imageType: "image/jpeg", // default
          //       debug: false, // default
          //       suppressErrorLogging: false, // default
          //       insertIntoEditor: undefined, // default
          //     }
          //   : false,
        },
        theme: "snow", // or 'bubble'
        content: "",
        placeholder: this.placeholder,
        id: this.id,
      },
      content: "",
    };
  },
  created() {
    if (this.toolbar === "false") this.editorOption.modules.toolbar = false;

    this.content = this.value;
  },
  computed: {
    cleanContent() {
      if (this.content) {
        return this.content;
        let regex = /(<([^>]+)>)/gi;
        return this.content.replace(regex, "");
      }
      return "";
    },
  },
  mounted() {},
  methods: {
    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    addEmoji(event) {
      const emoji = event.detail.unicode;
      const quill = this.$refs.myQuillEditor.quill;
      const range = quill.getSelection();
      quill.insertText(range.index, emoji);
      quill.setSelection(range.index + emoji.length);
      this.showEmojiPicker = false;
    },
  },
};
</script>

<style>
.quill-editor-wrapper {
  position: relative;
}

.emoji-picker-overlay {
  position: absolute;
  bottom: 60px; 
  right: 10px; 
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.ql-toolbar .ql-emoji::before {
  content: "😊";
  cursor: pointer; 
}
.ql-toolbar .ql-emoji:hover::before {
    background-color: red !important;
}
</style>
