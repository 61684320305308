import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function MeetingList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "name", sortable: true, label: "Name" },
        { key: "attendees", sortable: false, label: "Attendees" },
        { key: "join_url", sortable: false, label: "Join URL" },
        { key: "start_date_n_time", sortable: true, label: "Start time" },
        { key: "user.name", sortable: true, label: "Organized by" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalMeetingList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const meetingTypeFilter = ref(null);
    const organizeByFilter = ref(null);
    const attendeesByFilter = ref(null);
    const typeByFilter = ref('upcoming');
    const meetingsMetaData = ref([]);
    const meetingStatusOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalMeetingList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [
            currentPage,
            perPage,
            searchQuery,
            statusFilter,
            meetingTypeFilter,
            organizeByFilter,
            attendeesByFilter,
            typeByFilter
        ],
        () => {
            refetchData();
        }
    );

    const fetchMeetingList = (ctx, callback) => {
        store
            .dispatch("app-meetings/fetchMeetings", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                meetingType: meetingTypeFilter.value,
                organizeBy: organizeByFilter.value,
                attendeesBy: attendeesByFilter.value,
                type: typeByFilter.value
            })
            .then(response => {
                const meeting = response.data.data;
                const { total } = response.data.meta;
                meetingStatusOption.value = response.data.status;

                callback(meeting);
                totalMeetingList.value = total;
                meetingsMetaData.value = meeting;
            })
            .catch(error => {
                console.error(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching meetings",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchMeetingList,
        tableColumns,
        perPage,
        currentPage,
        totalMeetingList,
        meetingsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        meetingStatusOption,
        statusFilter,
        meetingTypeFilter,
        organizeByFilter,
        attendeesByFilter,
        typeByFilter
    };
}
